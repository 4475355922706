import React from "react";
import { graphql } from "gatsby";
import { NavigatedToState, FilmListProps } from "../types";
import Layout from "../components/layout";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import GoBack from "../components/GoBack";
import FilmList from "../components/FilmList";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  text: {
    alignText: "justify",
    fontSize: theme.typography.body1.fontSize
  },
  grid: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  img: { width: 200, float: "right", margin: theme.spacing(0, 0, 2, 2) },
  space: { height: theme.spacing(2) }
}));

export default function Template({
  data,
  location,
  pageContext: { year }
}: FilmListProps & NavigatedToState & { pageContext: { year: string } }) {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title={`${year} Ranked`} />
      <GoBack navigatedTo={location?.state?.navigatedTo} />
      <Divider className={classes.divider} />
      <FilmList data={data} />
    </Layout>
  );
}

export const postQuery = graphql`
  query YearByPath($year: Date!) {
    allFilmCsv(filter: { year: { eq: $year } }) {
      nodes {
        eTitle
        oTitle
        releaseDate
        rewatched
        imdb
        directors
        rating
        year
      }
    }
  }
`;
